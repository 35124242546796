export default {
  name: 'AgeChecked',
  methods: {
    mountAgeChecked() {
      if (!this.hasAgeCheckedSDK()) {
        const script = document.createElement('script')
        // script.src =
        //   'https://agechecked.verifico.io/tr/?domain=' +
        //   window.location.hostname

        script.src =
          'https://agechecked.verifico.io/tr/?domain=' +
          'www.yorkshiretrading.com'

        script.addEventListener('load', () => {
          console.log('AgeChecked SDK is loaded')
        })

        document.body.appendChild(script)
        console.log('Try to add AgeChecked SDK to DOM')
      } else {
        console.log('AgeChecked SDK is already loaded')
      }
    },
    hasAgeCheckedSDK() {
      return !!document.querySelectorAll(
        'script[src*="agechecked.verifico.io"]'
      ).length
    },
  },
}
