
import { assetURL } from '@/plugins/utilities'
import ageChecked from '~/mixins/ageChecked'

export default {
  components: {
    HomepageHeader: () =>
      import('@/containers/theme/HomepageHeader/HomepageHeader.vue'),
    HomepageBanner: () =>
      import('@/containers/theme/HomepageBanner/HomepageBanner.vue'),
    HomepageSpotlightFirst: () =>
      import('@/containers/theme/HomepageSpotlight/HomepageSpotlightFirst.vue'),
    HomepageSpotlightSecond: () =>
      import(
        '@/containers/theme/HomepageSpotlight/HomepageSpotlightSecond.vue'
      ),
    HomepageBestSellerFirst: () =>
      import(
        '@/containers/theme/HomepageBestSeller/HomepageBestSellerFirst.vue'
      ),
    HomepageBestSellerSecond: () =>
      import(
        '@/containers/theme/HomepageBestSeller/HomepageBestSellerSecond.vue'
      ),
    HomepageReviews: () =>
      import('@/containers/theme/HomepageReviews/HomepageReviews.vue'),
    HomepageCategories: () =>
      import('@/containers/theme/HomepageCategories/HomepageCategories.vue'),
    HomepageBlogs: () =>
      import('@/containers/theme/HomepageBlogs/HomepageBlogs.vue'),
    HomepageCompany: () =>
      import('@/containers/theme/HomepageCompany/HomepageCompany.vue'),
  },
  mixins: [ageChecked],
  asyncData({ app, $axios, query, redirect }) {
    if (query.q) {
      redirect(`/products?q=${query.q}`)
    }
  },
  data() {
    return {
      isDesktop: null,
      list: {
        technology: null,
        kitchen: null,
      },
    }
  },
  head() {
    return {
      title: this.$t('homepage.seoTitle', {
        country:
          this.$i18n.locale.toUpperCase() === 'EN'
            ? 'UK'
            : this.$i18n.locale.startsWith('en-')
            ? this.$i18n.locale.split('-')[1].toUpperCase()
            : this.$i18n.locale.toUpperCase(),
      }),
      titleTemplate: '%s',
      meta: [
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.$t('homepage.seoTitle', {
            country:
              this.$i18n.locale.toUpperCase() === 'EN'
                ? 'UK'
                : this.$i18n.locale.startsWith('en-')
                ? this.$i18n.locale.split('-')[1].toUpperCase()
                : this.$i18n.locale.toUpperCase(),
          }),
        },
        {
          hid: 'description',
          name: 'description',
          content: this.$t('homepage.seoDescription'),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$t('homepage.seoDescription'),
        },
      ],
    }
  },
  computed: {},
  mounted() {
    // if has a `ac_order_id` query param
    if (this.$route.query.ac_order_id) {
      this.mountAgeChecked()
    }

    this.$axios
      .get('/collections/803/products?order=trending&page=1&per_page=5')
      .then((response) => {
        this.list.technology = response?.data?.data || []
      })
      .catch((e) => {
        //
      })

    this.$axios
      .get('/collections/738/products?order=trending&page=1&per_page=5')
      .then((response) => {
        this.list.kitchen = response?.data?.data || []
      })
      .catch((e) => {
        //
      })
  },
  methods: {
    assetURL,
  },
}
